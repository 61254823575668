// @mui
import { styled } from '@mui/material/styles';
// components
import Page from '../components/Page';
// sections
import {
  HomeHero,
  HomeMinimal,
  HomeDarkMode,
  HomeLookingFor,
  HomeColorPresets,
  HomeChairman,
  HomeAdvertisement,
  HomeCleanInterfaces,
  HomePartners,
} from '../sections/home';
import { atom, useAtom } from 'jotai';
import Maintenance from './Maintenance';
import { AboutTeam } from 'src/sections/about';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }: { theme: any }) => ({
  height: '100%',
}));

const ContentStyle = styled('div')(({ theme }: { theme: any }) => ({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));
//change here
//change to true if need maintenance
//just change this to false if website is back up online
const isUnderMaintenance = true;
export const myAtom = atom(isUnderMaintenance);

// ----------------------------------------------------------------------

export default function HomePage() {
  return (
    <Page title="Home">
      <RootStyle>
        {isUnderMaintenance && <Maintenance />}
        {!isUnderMaintenance && (
          <>
            <HomeHero />
            <ContentStyle>
              <HomeMinimal />

              <HomeAdvertisement />

              {/* <HomeChairman /> */}
              <AboutTeam />

              <HomePartners />

              {/* <HomeDarkMode />

              <HomeColorPresets />

              <HomeCleanInterfaces />

              <HomeLookingFor /> */}
            </ContentStyle>
          </>
        )}
      </RootStyle>
    </Page>
  );
}
